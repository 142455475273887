import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import productDemo from "../assets/videos/product-demo.mp4"

const BundleTutorialPage = () => {
  return (
    <Layout>
      <SEO
        title="Product Demo"
        meta={[
          {
            name: "robots",
            content: "noindex, nofollow",
          },
        ]}
      />
      <div class="blog-container">
        <section class="page-hero ">
          <div class="container has-text-centered">
            <h1 class="title has-text-centered has-text-weight-light mb-5 is-size-2 is-size-4-mobile	">
              <span>Product Demo</span>
            </h1>
          </div>
        </section>
        <video
          src={productDemo}
          controls
          style={{
            aspectRatio: "16 / 9",
            backgroundColor: "black",
            marginTop: "1rem",
            width: "100%",
          }}
        ></video>
      </div>
    </Layout>
  )
}

export default BundleTutorialPage
